// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";
// normalize CSS across browsers
import "./src/normalize.css";
// adding bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// custom CSS styles
import "./src/style.css";
// highlighting for code blocks
import "prismjs/themes/prism.css";

export const onRouteUpdate = ({ location, prevLocation }) => {
  const { pathname } = location;
  if (
    pathname.indexOf("schlafberatung") !== -1 ||
    pathname.indexOf("schlafpakete") !== -1
  ) {
    if (document && location.hash) {
      let sub = 180;
      let el = location.hash.substring(1);
      let el2 = el;

      if (pathname.indexOf("schlafpakete") !== -1) {
        sub = 65;
        el = "1" + el;
      }

      const eleemnt = document.getElementById(el);
      if (eleemnt) {
        window.scrollTo(0, eleemnt.offsetTop - sub);
      } else if (document.getElementById(el2)) {
        setTimeout(
          () =>
            window.scrollTo(0, document.getElementById(el2).offsetTop - 100),
          500
        );
      }
    }
  }
};
